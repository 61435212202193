// noinspection JSUnusedGlobalSymbols

/**
 * General store for pages, also used by some components of user personal pages
 */

import { addToSentry } from "../../../js/tools/general";
// import {PageSpatialObject} from "./page-store--spatial--tools";
import ecotourism from "./page-store--ecotourism";

function getExternalAccountReferenceByTypeAndId(state, accountType, accountId) {
    // returns an array consisting of the object reference and the position in the array
    var result = [];

    switch (accountType) {
        case "twitter":
            state.externalAccounts.twitter.forEach(function (e, i) {
                if (e.id === accountId) {
                    result = [e, i]
                }
            });
            break;

        case "facebook":
            state.externalAccounts.facebook.forEach(function (e, i) {
                if (e.facebook_page_id === accountId) {
                    result = [e, i]
                }
            });
            break;


        case "instagram":
            state.externalAccounts.instagram.forEach(function (e, i) {
                if (e.instagram_user_id === accountId) {
                    result = [e, i]
                }
            });
            break;
    }

    return result
}

export default {
    namespaced: true,
    modules: {
        ecotourism
    },
    state: {
        initialLoadComplete: false,
        initialAdminLoadComplete: false,

        page_id: null,
        izele_page_type: null,
        is_conservation_area: false,
        is_conservation_group: false,
        is_business: false,
        accepts_visitors: false,
        charges_entrance: undefined,
        membership_scheme: undefined,
        sells_products_services: undefined,

        page_admins: [],

        countries: [],
        externalLinks: [],
        pageContacts: [],
        registeredUserCanComment: false,

        subscriptions: {},

        photoViewerInstance: null,  // move this to photo store?
        allActivities: [],
        externalAccounts: {
            filters: [],
            twitter: [],
            facebook: [],
            instagram: []
        },

        covid: {
            status: undefined,
            showBannerMessage: false,
            message: "",
            rawMessage: "",
            lastUpdate: ""
        },

        home_tab: {}
    }
    ,
    mutations: {
        setInitialData: function (state, data) {
            state.izele_page_type = data.izele_page_type;
            state.page_id = data.id;
            state.page_type = data.page_type;

            state.is_conservation_area = data.is_conservation_area;
            state.is_conservation_group = data.is_conservation_group;
            state.is_business = data.is_business;
            state.accepts_visitors = data.accepts_visitors;
            state.charges_entrance = data.charges_entrance;
            state.membership_scheme = data.membership_scheme;
            state.sells_products_services = data.sells_products_services;

            state.externalLinks = data.external_links;
            state.pageContacts = data.page_contacts;
            state.registeredUserCanComment = data.registered_user_can_comment;

            if (data.covid) {
                state.covid.status = data.covid.covid_status;
                state.covid.showBannerMessage = data.covid.covid_display_message;
                state.covid.message = data.covid.covid_message;
                state.covid.rawMessage = data.covid.covid_message_raw;
                state.covid.lastUpdate = data.covid.covid_last_update;
            }

            state.home_tab = data.home_tab;

            state.initialLoadComplete = true;
        },
        setInitialCountryList: function (state, countryList) {
            countryList.forEach(function (country) {
                if (country.iso_country_code === "-1") {
                    state.countries.unshift(country);  // put 'International' at the top of the list
                } else {
                    state.countries.push(country);
                }
            });
        },
        setInitialAdminData: function (state, data) {
            if ("admins" in data) {
                state.page_admins = data.admins;
            }

            if ("subscriptions" in data) {
                state.subscriptions = data.subscriptions;
            }

            // external sources
            state.externalAccounts.filters = data.external_sources.filters;
            state.externalAccounts.twitter = data.external_sources.twitter;

            var fbPage, igPage;
            data.external_sources.facebook.pages_accounts.forEach(function (page) {
                fbPage = page;
                fbPage["belongs_to_page"] = true;
                state.externalAccounts.facebook.push(fbPage)
            });
            data.external_sources.facebook.other_user_accounts.forEach(function (page) {
                fbPage = page;
                fbPage["belongs_to_page"] = false;
                state.externalAccounts.facebook.push(fbPage)
            })

            data.external_sources.instagram.pages_accounts.forEach(function (page) {
                igPage = page;
                igPage["belongs_to_page"] = true;
                state.externalAccounts.instagram.push(igPage)
            });
            data.external_sources.instagram.other_user_accounts.forEach(function (page) {
                igPage = page;
                igPage["belongs_to_page"] = false;
                state.externalAccounts.instagram.push(igPage)
            })

            state.initialAdminLoadComplete = true;
        },
        addCountry: function (state, countryObject) {
            if (countryObject.iso_country_code === "-1") {
                state.countries.unshift(countryObject);
            } else {
                state.countries.push(countryObject);
            }
        },
        removeCountry: function (state, countryId) {
            state.countries = state.countries.filter(function (e) {
                return e.iso_country_code !== countryId;
            })
        },
        addAvailableActivities: function (state, activities) {
            activities.forEach(function (activity) {
                activity.show = true;
                state.allActivities.push(activity);
            }.bind(this));
        },
        updatePhotoViewerInstance: function (state, instance) {
            state.photoViewerInstance = instance;
        },
        enableDisableFbOrIgAccount: function (state, data) {
            var idRef = data.account_type === "facebook" ? "facebook_page_id" :
                data.account_type === "instagram" ? "instagram_user_id" : undefined;

            state.externalAccounts[data.account_type].forEach(function (account) {
                if (account[idRef] === data.id) {
                    account.account_enabled = data.newState;
                }
            })
        },
        removeExternalAccount: function (state, data) {
            state.externalAccounts[data.account_type].splice(getExternalAccountReferenceByTypeAndId(state, data.account_type, data.account_id)[1], 1)
        },
        addTwitterAccount: function (state, data) {
            state.externalAccounts.twitter.push(data);
        },
        addContact: function (state, contactData) {
            state.pageContacts.push(contactData);
        },
        removeContact: function (state, contactId) {
            state.pageContacts = state.pageContacts.filter(function (e) {
                return e.id !== contactId
            })
        },
        highlightPageContact: function (state, contactData) {
            for (var i=0; i < state.pageContacts.length; i++) {
                if (state.pageContacts[i].id === contactData.id) {
                    state.pageContacts[i].is_primary = contactData.state;
                    break;
                }
            }
        },
        updateContact: function (state, contactData) {
            for (var i=0; i < state.pageContacts.length; i++) {
                if (state.pageContacts[i].id === contactData.id) {
                    state.pageContacts[i].contact = contactData.contact;
                    break;
                }
            }
        },
        addExternalLink: function (state, linkData) {
            state.externalLinks.push(linkData)
        },
        removeExternalLink: function (state, linkId) {
            state.externalLinks = state.externalLinks.filter(function (e) {
                return e.id !== linkId
            })
        },
        highlightPageExternalLink: function (state, linkData) {
            for (var i=0; i < state.externalLinks.length; i++) {
                if (state.externalLinks[i].id === linkData.id) {
                    state.externalLinks[i].is_primary = linkData.state;
                    break;
                }
            }
        },
        updateExternalLink: function (state, linkData) {
            for (var i=0; i < state.externalLinks.length; i++) {
                if (state.externalLinks[i].id === linkData.id) {
                    state.externalLinks[i].link = linkData.external_link;
                    break;
                }
            }
        },
        updateExternalLinkText: function (state, linkData) {
            for (var i=0; i < state.externalLinks.length; i++) {
                if (state.externalLinks[i].id === linkData.id) {
                    state.externalLinks[i].link_text = linkData.external_link_text;
                    break;
                }
            }
        },
        updateCovidState: function (state, data) {
            state.covid.status = data.covid_status;
            state.covid.showBannerMessage = data.covid_display_message;
            state.covid.message = data.covid_message;
            state.covid.rawMessage = data.covid_message_raw;
        },
        addFilter: function (state, filterText) {
            state.externalAccounts.filters.push(filterText)
        },
        removeFilter: function (state, filterText) {
            state.externalAccounts.filters = state.externalAccounts.filters.filter(function (e) {
                return e !== filterText
            })
        },
        removeAdmin: function (state, adminId) {
            state.page_admins = state.page_admins.filter(function (e) {
                return e.id !== adminId
            })
        },
        addAdmin: function (state, adminObject) {
            adminObject.user_name = adminObject.first_name + " " + adminObject.last_name;
            state.page_admins.push(adminObject)
        },
        updateRegisteredUserCanComment: function (state, useCanComment) {
            state.registeredUserCanComment = useCanComment
        },
        addDataRelevantToPersonalPages: function (state, data) {
            state.registeredUserCanComment = data.allow_user_comments;
            state.initialLoadComplete = true;
        }
    },
    actions: {
        addInitialData: function (context, data) {
            context.commit("setInitialData", data);
            context.commit("setInitialCountryList", data.countries);
        },
        addPersonalPageData: function (context, data) {
            context.commit("addDataRelevantToPersonalPages", data)
            if (izele.userData.isAdminForThisPage) {
                context.commit("setInitialAdminData", data)
            }

        }
    },
    getters: {
        currentPhotoViewerInstance: function (state) {
            return state.photoViewerInstance
        },
        orderedExternalLinks: function (state) {
            var links = [];

            state.externalLinks.forEach(function (link) {
                if (link.is_primary) {
                    links.unshift(link);
                } else {
                    links.push(link);
                }
            });

            return links;
        },
        highlightedUserLinks: function (state) {
            var links = [];

            state.externalLinks.forEach(function (link) {
                if (link.is_primary) {
                    links.push(link);
                }
            });

            return links;
        },
        orderedPageContacts: function (state) {
            var contacts = [];

            state.pageContacts.forEach(function (contact) {
                if (contact.is_primary) {
                    contacts.unshift(contact);
                } else {
                    contacts.push(contact);
                }
            });

            return contacts;
        },
        highlightedContacts: function (state) {
            var contacts = [];

            state.pageContacts.forEach(function (contact) {
                if (contact.is_primary) {
                    contacts.push(contact);
                }
            });

            return contacts;
        },
        prettyCovidStatus: function (state) {
            switch (state.covid.status) {
                case "open":
                    return gettext("open")
                case "closed":
                    return gettext("closed")
                case "open_restrictions":
                    return gettext("open (with restrictions)")
            }
        }
    }
}